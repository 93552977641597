import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Sales from './Sales';

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/:jsonFile">
          <Sales />
        </Route>
      </Switch>
    </Router>
  );
}
