import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import './Sales.css';

function Sales() {
  const { jsonFile } = useParams();
  const query = new URLSearchParams(window.location.search);
  const [currentPage, setCurrentPage] = useState(query.get('page') || 0);
  const [requireNumber, setRequireNumber] = useState(false);
  const [allSales, setAllSales] = useState([]);
  const [sales, setSales] = useState([]);
  const [matches, setMatches] = useState([]);
  const [sale, setSale] = useState(null);

  useEffect(()=>{
    if (sales.length) return;
    const getData = async () => {
      const response = await fetch(`${jsonFile}.json`);
      const salesData = await response.json();
      setAllSales(salesData);
      setSales(salesData);
      setSale(sales[currentPage]);
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!sale) return;

    const getMatches = async (includeNumber=true) => {
      const url = new URL('https://collx-api-sa3kylxkwa-uc.a.run.app/v1/cards')
      const params = {
        limit: 100,
        year: sale.year,
        player: sale.player_name,
      }
      if (sale.card_number != null) {
        params.number = sale.card_number;
      }
      url.search = new URLSearchParams(params).toString();
      const response = await fetch(url, {
        method:'GET',
        headers: {'Authorization': 'Basic ' + btoa('data@collx.app:password')}});
      const items = await response.json();

      if (!items.length) {
        setCurrentPage(currentPage+1);
      }
      setMatches(items.sort((a, b) => b.frontImageUrl? 1 :-1));
    }

    getMatches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sale]);

  useEffect(() => {
    if (!requireNumber) {
      setSales(allSales);
      return;
    }
    setSales(allSales.filter((s) => s.card_number))
  }, [requireNumber, allSales])

  useEffect(() => {
    setSale(sales[currentPage]);
  }, [currentPage, sales]);

  if (!sale) return (
    <div className="w-100 text-center p-5">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );

  return (
    <div className="container-fluid my-3">
      <div className="d-flex mb-3">
        {currentPage > 0 && (
          <div>
            <button
              className="btn btn-primary h-100 d-inline-flex align-items-center"
              onClick={() => setCurrentPage(currentPage-1)}>
              <i className="bi bi-chevron-compact-left"></i>
            </button>
          </div>
        )}
        <div className="flex-grow-1">
          <div className="px-3">
            <h2>{sale.title}</h2>
            <p>
              Page: {currentPage} |&nbsp;
              Year: {sale.year} |&nbsp;
              Player: {sale.player_name} |&nbsp;
              <span className={requireNumber ? 'active cursor-pointer' : 'cursor-pointer'} onClick={() => setRequireNumber(!requireNumber)}>Number: {sale.card_number}</span> |&nbsp;
              Set: {sale.set_name} |&nbsp;
              Results: <span className="badge bg-secondary">{matches.length}</span> |&nbsp;
              Image Results: <span className="badge bg-secondary">{matches.filter((c) => c.frontImageUrl).length}</span>
            </p>
          </div>
        </div>
        <div>
          <button
            className="btn btn-primary h-100 d-inline-flex align-items-center"
            onClick={() => setCurrentPage(currentPage+1)}>
            <i className="bi bi-chevron-compact-right"></i>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
        <div className="col">
          <div className="card mb-3">
            <img className="card-img" src={sale.image_url} alt={sale.player_name} />
          </div>
          <ul>
            <li><strong>URL</strong>: <a href={sale.url} target="_blank">{sale.url}</a></li>
            <li><strong>Price</strong>: {sale.price}</li>
            {Object.entries(sale.attributes).map(([k,v])  => (
              <li key={k}><strong>{k}</strong>: {v}</li>
            ))}
          </ul>
        </div>
        </div>
        <div className="col-9">
          <div className="row">
            {matches.map((match) => {
              return (
                <div key={match.id} className="col-2 mb-4">
                  <div className="card h-100">
                    {match.frontImageUrl && (<img className="card-img-top" src={match.frontImageUrl} alt={match.name} />)}
                    <div className="card-body">
                      <strong className="card-title">Name:{match.name}</strong><br/>
                      <small className="card-text">Number: {match.number}</small><br/>
                      <small className="card-text">Set: {match.set}</small><br/>
                      <small className="card-brand">Brand: {match.brand}</small>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sales;
